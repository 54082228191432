/*###########################################################################
// Slider gallery
//#########################################################################*/

import Swiper from 'swiper'

const sliderGallery = {
  config: {
    sliderGallery: '.sliderGallery'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderGalleries = document.querySelectorAll(self.config.sliderGallery)

    if (self.elements.sliderGalleries !== null) {
      for (let i = 0; i < self.elements.sliderGalleries.length; i++) {
        const sliderGallery = self.elements.sliderGalleries[i]

        self.handleSliderGallery(sliderGallery)
      }
    }
  },

  handleSliderGallery(slider) {
    const self = this

    const prevBtn = slider.parentNode.querySelector('.slider__control--prev')
    const nextBtn = slider.parentNode.querySelector('.slider__control--next')

		const progressBar = slider.parentNode.querySelector('.slider__progress__bar')

    const swiper = new Swiper(slider, {
      slidesPerView: "auto",
      spaceBetween: 0,
      loop: false,
      autoHeight: true,
    })

    if (swiper.isBeginning) {
      prevBtn.classList.add('is-disabled')
    }

    swiper.on('slideChange', function() {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }



      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }

			progressBar.style.width = ((100 / swiper.slides.length) * (swiper.activeIndex + 1)) + '%'
    })

    swiper.on('fromEdge', function() {
      prevBtn.classList.remove('is-disabled')
      nextBtn.classList.remove('is-disabled')
    })

    swiper.on('reachBeginning', function () {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }
    })

    swiper.on('reachEnd', function () {
      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }
    })

    prevBtn.addEventListener('click', function (e) {
      swiper.slidePrev()
    })

    nextBtn.addEventListener('click', function (e) {
      swiper.slideNext()
    })
  }
}

window.addEventListener('load', function () {
  sliderGallery.init()
})
