/*###########################################################################
// Slider logo
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules';

const sliderLogo = {
  config: {
    sliderLogo: '.sliderLogo'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderLogos = document.querySelectorAll(self.config.sliderLogo)

    if (self.elements.sliderLogos !== null) {
      for (let i = 0; i < self.elements.sliderLogos.length; i++) {
        const sliderLogo = self.elements.sliderLogos[i]

        self.handleSliderLogo(sliderLogo)
      }
    }
  },

  handleSliderLogo(slider) {
    const self = this

		const slidesAmount = parseInt(slider.getAttribute('data-slides'))

    const swiper = new Swiper(slider, {
			modules: [Autoplay],
      slidesPerView: 4,
      spaceBetween: 40,
			autoplay: {
				delay: 1000,
				disableOnInteraction: false,
			},
			speed: 3000,
      loop: slidesAmount > 6 ? true : false,
      autoHeight: false,
			breakpoints: {

			}
    })
  }
}

window.addEventListener('load', function () {
  sliderLogo.init()
})
