import tippy from 'tippy.js';


window.addEventListener('load', function() {
	const tippyEls = document.querySelectorAll('.tippy')

	for (let i = 0; i < tippyEls.length; i++) {
		const tippyEl = tippyEls[i]
		const tippyTooltipText = tippyEl.getAttribute('data-tippy-content')

		tippy(tippyEl, {
			content: tippyTooltipText,
			delay: [0, 50],
			hideOnClick: false
		});

		if (tippyEl.hasAttribute('data-tippy-copy-url') && tippyEl.getAttribute('data-tippy-copy-url') == 'true') {
			tippyEl.addEventListener('click', function(e) {
				e.preventDefault()

				navigator.clipboard.writeText(window.location);

				tippyEl._tippy.setProps({
					content: 'Copied URL',
					delay: [0, 1000],
				})

				setTimeout(function() {
					tippyEl._tippy.setProps({
						content: tippyEl.getAttribute('data-tippy-content'),
						delay: [0, 50]
					})
					tippyEl.style.pointerEvents = ''
				}, 1000)
			})
		}
	}

})
