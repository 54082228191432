const forms = {
	config: {
		field: '.field'
	},

	elements: {},

	init() {
		const self = this

		self.elements.fields = document.querySelectorAll(self.config.field)

		if (self.elements.fields !== null) {
			for (let i = 0; i < self.elements.fields.length; i++) {
				const field = self.elements.fields[i]

				self.handleInput(field)
			}
		}
	},

	handleInput(field) {
		const input = field.querySelector(".input")

		if (input !== null) {
			if (input.value !== '' || input.getAttribute('type') == 'date') {
				field.classList.add('has--content')
			} else {
				field.classList.remove('has--content')
			}

			input.addEventListener('change', function () {
				if (input.value !== '' || input.getAttribute('type') == 'date') {
					field.classList.add('has--content')
				} else {
					field.classList.remove('has--content')
				}
			})

			input.addEventListener('focus', function () {
				field.classList.add('has--focus')
			})

			input.addEventListener('blur', function () {
				field.classList.remove('has--focus')
			})
		}
	}
}

window.addEventListener('load', function () {
	forms.init()
})
