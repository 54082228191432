/*###########################################################################
// Accordions
//#########################################################################*/

import { debounce } from '../utilities/_throttleDebounce'

const accordions = {
    config: {
        accordion: '.accordion',
        accordionHeader: '.accordion__header',
        accordionContent: '.accordion__content'
    },

    elements: {
        accordions: null
    },

    init() {
        const self = this

        self.elements.accordions = document.querySelectorAll(self.config.accordion)

        if (self.elements.accordions !== null) {
            for (let i = 0; i < self.elements.accordions.length; i++) {
                const accordion = self.elements.accordions[i]

                self.handleAccordion(accordion)
            }
        }
    },

    handleAccordion(accordion) {
        const self = this

        const accordionHeader = accordion.querySelector(self.config.accordionHeader)
        const accordionContent = accordion.querySelector(self.config.accordionContent)

        let accordionContentHeight = accordionContent.scrollHeight
        accordion.setAttribute('data-content-height', accordionContentHeight)

        accordionHeader.addEventListener('click', function() {
            if (accordion.classList.contains('is-active')) {
                accordion.classList.remove('is-active')
                accordionContent.style.height = '0px'
            } else {
                accordion.classList.add('is-active')
                accordionContent.style.height = accordion.getAttribute('data-content-height') + 'px'
            }
        })

        if (accordion.classList.contains('is-active')) {
            accordionContent.style.height = accordion.getAttribute('data-content-height') + 'px'
        }

        window.addEventListener('resize', debounce(function() {
            accordionContent.style.height = ''
            accordion.setAttribute('data-content-height', 0)

            accordionContentHeight = accordionContent.scrollHeight
            accordion.setAttribute('data-content-height', accordionContentHeight)

            if (accordion.classList.contains('is-active')) {
                accordionContent.style.height = accordionContent.scrollHeight + 'px'
                accordion.setAttribute('data-content-height', accordionContentHeight)
            }
        }, 500))
    }
}

window.addEventListener('load', function () {
    setTimeout(function() {
			accordions.init()
		}, 500)
})
