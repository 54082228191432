/*###########################################################################
// Slider cards
//#########################################################################*/

import Swiper from "swiper"
import { debounce } from "@js/utilities/_throttleDebounce"

const sliderCards = {
  config: {
    sliderCards: '.sliderCards'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderCards = document.querySelectorAll(self.config.sliderCards)

    if (self.elements.sliderCards !== null) {
      for (let i = 0; i < self.elements.sliderCards.length; i++) {
        const sliderCards = self.elements.sliderCards[i]

        self.handleSliderCards(sliderCards)
      }
    }
  },

  initSlider(slider) {
    const prevBtn = slider.parentNode.querySelector('.slider__control--prev')
    const nextBtn = slider.parentNode.querySelector('.slider__control--next')

    const swiper = new Swiper(slider, {
      slidesPerView: 1.1,
      spaceBetween: 20,
      loop: false,
      autoHeight: false,
      breakpoints: {
        360: {
          slidesPerView: 1.25,
          spaceBetween: 20,
        },
        414: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        520: {
          slidesPerView: 1.75,
          spaceBetween: 20,
        },
        700: {
          slidesPerView: 2.75,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 2.25,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 2.75,
          spaceBetween: 32,
        },
				1340: {
          slidesPerView: 2.75,
          spaceBetween: 32
        }
      }
    })

    if (prevBtn !== null && nextBtn !== null && swiper && !swiper.destroyed) {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }

      swiper.on('slideChange', function() {
        if (swiper.isBeginning) {
          prevBtn.classList.add('is-disabled')
        }

        if (swiper.isEnd) {
          nextBtn.classList.add('is-disabled')
        }
      })

      swiper.on('fromEdge', function() {
        prevBtn.classList.remove('is-disabled')
        nextBtn.classList.remove('is-disabled')
      })

      swiper.on('reachBeginning', function () {
        if (swiper.isBeginning) {
          prevBtn.classList.add('is-disabled')
        }
      })

      swiper.on('reachEnd', function () {
        if (swiper.isEnd) {
          nextBtn.classList.add('is-disabled')
        }
      })

      prevBtn.addEventListener('click', function (e) {
        swiper.slidePrev(500)
      })

      nextBtn.addEventListener('click', function (e) {
        swiper.slideNext(500)
      })

      // Workaround for weird bug of miscalculations
      //
      swiper.update()
    }

    return swiper
  },

  handleSliderCards(slider) {
    const self = this

    let swiper = self.initSlider(slider)
    let width = window.innerWidth

    window.addEventListener("resize", debounce(function() {
      if (window.innerWidth !== width) {
        swiper.destroy()

        setTimeout(function() {
          swiper = self.initSlider(slider)
        }, 500)

        width = window.innerWidth
      }
    }, 500))
  }
}

window.addEventListener('load', function () {
  sliderCards.init()
})
