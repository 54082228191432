/*###########################################################################
// Hero
//#########################################################################*/

import Swiper from "swiper"
import { Autoplay } from 'swiper/modules';

const hero = {
  config: {
    hero: '.hero',
		heroHome: '.heroHome'
  },

  elements: {},

  init() {
    const self = this

    self.elements.heroes = document.querySelectorAll(self.config.hero)

    if (self.elements.heroes !== null) {
			for (let i = 0; i < self.elements.heroes.length; i++) {
				const hero = self.elements.heroes[i]

				self.handleHero(hero)
			}
    }

		self.elements.heroHome = document.querySelector(self.config.heroHome)

    if (self.elements.heroHome !== null) {
			self.handleHeroHome()
    }
  },

	handleHero(hero) {
		const self = this

		setTimeout(function() {
			hero.classList.add('has-loaded')
		}, 250)
	},

	handleHeroHome() {
		const self = this

		setTimeout(function() {
			self.elements.heroHome.classList.add('has-loaded')

			self.handleHeroHomeScrollerWords()
			self.handleHeroHomeLatest()
		}, 500)
	},

	handleHeroHomeScrollerWords() {
		const self = this

		const scrollerWordsContainer = self.elements.heroHome.querySelector('.heroHome__scroller-words')
		const scrollerWords = self.elements.heroHome.querySelectorAll('.heroHome__scroller-word')

		const swiper = new Swiper(scrollerWordsContainer, {
      slidesPerView: 1,
      loop: true,
			direction: 'vertical',
			modules: [Autoplay],
			autoplay: {
				delay: 4000,
				disableOnInteraction: false
			},
    })
	},

	handleHeroHomeLatest() {
		const self = this

		const latestSlider = self.elements.heroHome.querySelector('.heroHome__latest__slider')
		const latestProgressBar = self.elements.heroHome.querySelector('.heroHome__latest__progress__bar')

		const swiper = new Swiper(latestSlider, {
      slidesPerView: 1,
      loop: true,
			direction: 'vertical',
			modules: [Autoplay],
			autoHeight: false,
			height: 62,
			autoplay: {
				delay: 3000,
				disableOnInteraction: false
			},
    })

		let leftOffset = 0

		swiper.on('slideChange', function () {
			leftOffset = latestProgressBar.clientWidth * (swiper.realIndex)

			if (swiper.realIndex === 0) {
				leftOffset = 0
			}

			latestProgressBar.style.transform = 'translateX(' + leftOffset + 'px)'
		});
	}
}

window.addEventListener('load', function () {
  hero.init()
})
